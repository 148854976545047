import React from "react";
import { json, redirect } from "@remix-run/node";
import {
  Form,
  Link as RemixLink,
  useActionData,
  useNavigation,
} from "@remix-run/react";
import { User } from "~/models";
import { sendEmail, InactiveRecipientsError } from "~/utils/email.server";
import { getFormData } from "~/utils/form.server";
import { badRequest } from "~/utils/response.server";
import { generateToken } from "~/utils/jwt.server";

import {
  Box,
  Heading,
  Text,
  Stack,
  Input,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Link,
  Flex,
  Button,
  Container,
  HStack,
  VisuallyHidden,
  useColorModeValue as mode,
} from "@chakra-ui/react";

import { CallToAction, Paper } from "~/components";

import { EMAIL_REGEX } from "~/utils/regex";

export function meta() {
  return [
    { title: "Login | Schnerp" },
    {
      name: "description",
      content:
        "Log into your Schnerp account to check your requested campground availability",
    },
  ];
}

export async function action({ request }) {
  const { email } = await getFormData(request);

  if (!email.match(EMAIL_REGEX)) {
    return badRequest({
      error: `Invalid email address`,
    });
  }

  // logged out db user
  const user = await User.findByEmail(email);

  // new user
  // return json so we can handle this error on the form
  if (!user) {
    return badRequest({
      error: `No account found. We'll make an account after you create your first request.`,
    });
  }

  const token = await generateToken({ id: user.id });

  if (
    process.env.APP_ENV === "development" &&
    process.env.APP_UNSAFE_AUTO_LOGIN === "true"
  ) {
    return redirect(`/login/${token}`);
  }

  try {
    await sendEmail({
      template: "login",
      to: user.email,
      locals: {
        token,
      },
    });
  } catch (e) {
    if (e instanceof InactiveRecipientsError) {
      return badRequest({
        error: `This account has been disabled due to a previous bounce or spam complaint. Please email help@schnerp.com with a screenshot of this message to re-activate your account.`,
      });
    }
    throw e;
  }

  return json({
    email: user.email,
    success: true,
  });
}

export default function Login() {
  const data = useActionData();
  const { state } = useNavigation();
  const isSubmitting = state === "submitting";
  const inputRef = React.useRef();

  React.useEffect(() => {
    if (data?.error) {
      inputRef.current.focus();
    }
  }, [data]);

  return (
    <>
      <Container maxW="md" py={{ base: "12", md: "24" }}>
        <Stack spacing="8">
          <Stack spacing="6">
            <Stack spacing={{ base: "2", md: "3" }} textAlign="center">
              <Heading size="sm" fontWeight="extrabold">
                Log in to your account
              </Heading>
              <Text color="subtle">
                We don't use passwords on this site. Enter your email, and we'll
                send you a magic link to login with.
              </Text>
            </Stack>
          </Stack>
          <Stack spacing="6">
            {data?.success ? (
              <Paper p={4} data-testid="login-success">
                <Text mb={4}>
                  We sent an email to: <strong>{data.email}</strong>
                </Text>
                <Text mb={4}>
                  Check your inbox, click on the link, and we'll log you in 🎩✨
                </Text>
                <Link as={RemixLink} to=".">
                  Wrong email?
                </Link>
              </Paper>
            ) : (
              <Stack spacing="4" as={Form} method="post">
                <FormControl
                  isRequired
                  isInvalid={data?.error}
                  fontSize="inherit"
                >
                  <VisuallyHidden>
                    <FormLabel>Enter your email address</FormLabel>
                  </VisuallyHidden>
                  <Input
                    ref={inputRef}
                    name="email"
                    type="email"
                    placeholder="Enter your email address"
                  />
                  {data?.error ? (
                    <FormErrorMessage>{data.error}</FormErrorMessage>
                  ) : null}
                </FormControl>
                <Button
                  variant="primary"
                  type="submit"
                  isLoading={isSubmitting}
                >
                  Continue with email
                </Button>
              </Stack>
            )}
          </Stack>
          <HStack spacing="1" justify="center">
            <Text fontSize="sm" color="subtle">
              Having issues?
            </Text>
            <Button
              variant="link"
              colorScheme="brand"
              size="sm"
              as={Link}
              isExternal
              href="mailto:help@schnerp.com?Subject=Schnerp%20Login%20Help"
            >
              Contact us
            </Button>
          </HStack>
        </Stack>
      </Container>
      <CallToAction
        heading="No account yet?"
        body="We'll make a free account after you create your first request."
        buttonText="Create a request"
        buttonProps={{
          as: RemixLink,
          to: "/new",
        }}
      />
    </>
  );
}
